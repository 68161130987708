import React, { useState, useEffect } from 'react';
import '../Banner/Banner.css';
import './Form2.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';

const Form2 = () => {
  const [response, setResponse] = useState(false);
  const [headingColor, setHeadingColor] = useState("#000000"); // Initial heading color is black
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    textarea: '',
    number: '',
    consentSms: false,
    consentDetails: false
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Toggle between black and blue
      setHeadingColor(prevColor => prevColor === "#000000" ? "#22D3EE" : "#000000");
    }, 1000);

    // Clean up interval
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    setResponse(true);
    try {
      const response = await axios.post('https://the-readsy-ten.vercel.app/api/messages', formData);
      setResponse(false);
      alert('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        textarea: '',
        number: '',
        consentSms: false,
        consentDetails: false
      }); // Reset form fields
    } catch (error) {
      setResponse(false);
      console.error('Error sending data:', error);
      alert('Error sending message');
    }
  };

  return (
    <>
      <div className='form-shadow form2-res rounded-2xl md:mt-0'>
        <h1 style={{ color: headingColor, fontFamily: "sans-serif" }} className='md:text-3xl text-xl font-bold text-center'>
          Grab Your Exclusive Discount Today!
        </h1>
        <p style={{ fontFamily: "sans-serif" }} className='text-center font-bold md:text-md text-sm'>
          Attention, fellow book enthusiasts! <br />
          Avail of our amazing discount before time runs out
        </p>
        <div className='flex flex-col'>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="my-2 py-3 px-4 block w-full border border-gray-300 rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Full Name"
          />
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="my-2 py-3 px-4 block w-full border border-gray-300 rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Email Address"
          />
          <input
            type="text"
            name="number"
            value={formData.number}
            onChange={handleChange}
            className="my-2 py-3 px-4 block w-full border border-gray-300 bg-gray-50 rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Phone Number"
          />
          <textarea
            name="textarea"
            value={formData.textarea}
            onChange={handleChange}
            rows="4"
            className="my-2 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            placeholder="Write your thoughts here..."
          ></textarea>

          <div className='my-2 flex items-start'>
            <input
              type="checkbox"
              name="consentSms"
              checked={formData.consentSms}
              onChange={handleChange}
              className='mr-2 mt-1'
            />
            <label htmlFor="consentSms" className='text-sm'>
              By providing a telephone number and submitting this form, you are consenting to be contacted by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
            </label>
          </div>
          <div className='my-2 flex items-start'>
            <input
              type="checkbox"
              name="consentDetails"
              checked={formData.consentDetails}
              onChange={handleChange}
              className='mr-2 mt-1'
            />
            <label htmlFor="consentDetails" className='text-sm'>
              I consent to receive SMS/MMS messages from The Readsy.
            </label>
          </div>
          {
            response ? (
              <div className='flex justify-center'>
                <RotatingLines
                  visible={true}
                  strokeColor='#22D3EE'
                  height="50"
                  width="50"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass="m-auto"
                />
              </div>
            ) : (
              <button
                onClick={handleSubmit}
                className="form-button-full w-full bg-cyan-400 text-white font-bold py-2 px-4 rounded-full"
              >
                CONTACT WITH US
              </button>
            )
          }
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Form2;
