import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Form2.css';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const Form = () => {
  const [response, setResponse] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    textarea: '',
    number: '',
    consentSms: false,
    consentDetails: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    setResponse(true);

    try {
      const response = await axios.post('https://the-readsy-ten.vercel.app/api/messages', formData);
      setResponse(false);
      console.log('API response:', response.data);
      alert('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        textarea: '',
        number: '',
      }); // Reset form fields
    } catch (error) {
      setResponse(false);
      console.error('Error sending data:', error);
      alert('Error sending message');
    }
  };

  return (
    <div className='form-shadow'>
      <h1 style={{ color: "#0A2840" }} className='text-2xl font-medium text-center'>
        Start Your Publishing <br />Journey!
      </h1>
      <div className='flex flex-col'>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className='my-2 py-3 px-4 block w-full border border-gray-400 rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none'
          placeholder="Full Name"
        />
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className='my-2 py-3 px-4 block w-full border border-gray-400 rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none'
          placeholder="Email Address"
        />
        <input
          type="text"
          name="number"
          value={formData.number}
          onChange={handleChange}
          className='my-2 py-3 px-4 block w-full border border-gray-400 rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none'
          placeholder="Phone Number"
        />
        <textarea
          id="textarea"
          name="textarea"
          value={formData.textarea}
          onChange={handleChange}
          rows="4"
          className='my-2 block p-2.5 w-full text-sm border border-gray-400 bg-gray-50 rounded-lg'
          placeholder="Write your thoughts here..."
        ></textarea>
        <div className='my-2 flex items-start'>
          <input
            type="checkbox"
            name="consentSms"
            checked={formData.consentSms}
            onChange={handleChange}
            className='mr-2 mt-1'
          />
          <label htmlFor="consentSms" className='text-sm w-96'>
            By providing a telephone number and submitting this form, you are consenting to be contacted by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
          </label>
        </div>
        <div className='my-2 flex items-start'>
          <input
            type="checkbox"
            name="consentDetails"
            checked={formData.consentDetails}
            onChange={handleChange}
            className='mr-2 mt-1'
          />
          <label htmlFor="consentDetails" className='text-sm w-96'>
            I consent to receive SMS/MMS messages from The Readsy.
          </label>
        </div>
        {
          response ? (
            <div className='flex justify-center'>
              <RotatingLines
                visible={true}
                strokeColor='#22D3EE'
                height="50"
                width="50"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass="m-auto"
              />
            </div>
          ) : (
            <button
              onClick={handleSubmit}
              className='form-button-full w-96 bg-cyan-400 text-white font-bold py-2 px-4 rounded-full'
            >
              CONTACT WITH US
            </button>
            
          )
        }
        <p style={{"color" :"#232F3F"}} className='text-sm font-medium   md:w-96  mt-2 ' >Note: You can find the number on the page Header. <Link to={'/Privacy-Policy'}  className='font-bold' >  Privacy Policy </Link> can be found in the footer.</p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Form;
